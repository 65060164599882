<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">NEW CORPORATE ACCOUNT</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-col
              md="4"
              cols="12"
            >
              <v-text-field
                readonly
                v-model="branch"
                label="Branch"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-select
                v-model="type_of_account"
                dense
                outlined
                label="Type of Account"
                :items="['Corporate']"
                :rules="rules.combobox_rule"
              ></v-select>
            </v-col>
            <v-card
              flat
              class="mx-3 mt-3"
            >
              <v-toolbar dense dark color="primary">
                <v-toolbar-title><h4 class="font-weight-light">CORPORATE INFORMATION</h4>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-col
                  md="6"
                  cols="12"
                >
                  <v-select
                    v-model="type_of_account_corporate"
                    dense
                    outlined
                    label="Type of Corporate"
                    :items="['Single Proprietorship','Registered Partnership','Registered Corporation','Registered Association/Coop & Organization','Government Owned & Controlled Corporation','Unregistered Association/Organization/Societies/Joint Ventures','Treasurer-in-Trust','Foreign Entity','Others']"
                    :rules="rules.combobox_rule"
                  ></v-select>
                </v-col>
                <v-col
                  md="12"
                  cols="12"
                >
                  <v-text-field
                    v-model="account_name"
                    label="Business Name"
                    dense
                    outlined
                    :rules="rules.default_max_100_character_and_no_empty_rule"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    v-model="business_address"
                    label="Business Address"
                    dense
                    outlined
                    :rules="rules.default_max_255_character_and_no_empty_rule"
                  ></v-text-field>
                </v-col>
                <v-row>
                  <v-col
                    md="3"
                    cols="12"
                  >
                    <v-text-field
                      type="date"
                      v-model="date_of_registration"
                      label="Date Of Registration"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="3"
                    cols="12"
                  >
                    <v-text-field
                      v-model="place_of_registration"
                      label="Place Of Registration"
                      dense
                      outlined
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="3"
                    cols="12"
                  >
                    <v-text-field
                      v-model="registration_no"
                      label="Registration Number"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="3"
                    cols="12"
                  >
                    <v-text-field
                      v-model="tin_no"
                      label="TIN Number"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="9"
                    cols="12"
                  >
                    <v-select
                      v-model="nature_of_business"
                      dense
                      outlined
                      label="Nature of Business"
                      :items="['Financial Services','Retail,Wholesale','Food & Beverage Industries','Trading','Manufacturing','Leasin/Rental','Transportation','Communication','Real Estate & Construction','Utilities','Others']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col
                    md="3"
                    cols="12"
                  >
                    <v-text-field
                      type="number"
                      :min="0"
                      v-model="gross_annual_income"
                      label="Gross Annual Income"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="telephone_no"
                      label="Telephone Number"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="fax_no"
                      label="Fax Number"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="4"
                    cols="12"
                  >
                    <v-text-field
                      v-model="email_address"
                      label="Email Address"
                      dense
                      outlined
                      :rules="rules.default_max_75_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-col cols="12" v-if="alert">
                  <v-alert
                    color="warning"
                    text
                    class="mb-0"

                  >
                    <div class="d-flex align-start">
                      <v-icon color="warning">
                        {{ icons.mdiAlertOutline }}
                      </v-icon>

                      <div class="ms-3">
                        <p class="text-base font-weight-medium mb-1">
                          {{alert_message}}
                        </p>
                      </div>
                    </div>
                  </v-alert>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    color="primary"
                    class="me-3 mt-4"
                    @click="save_loan_application"
                    v-if="!saving"
                  >
                    Save changes
                  </v-btn>
                  <v-progress-circular
                    :size=50
                    :width="5"
                    color="primary"
                    indeterminate
                    v-else
                  ></v-progress-circular>
                </v-col>
              </v-card-text>
            </v-card>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'
  import 'croppie/croppie.css'// import the croppie css manually
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      saving: false,
      alert: false,
      alert_message: '',

      avatarImg: require('@/assets/images/avatars/1.png'),
      croppieImage_mem_1: '',
      cropped: require('@/assets/images/avatars/1.png'),
      croppieImage_mem_2: '',
      cropped_bus: require('@/assets/images/avatars/1.png'),
      croppieImage_mem_3: '',
      cropped_home: require('@/assets/images/avatars/1.png'),

      type_of_account: '',
      date_of_member: '',
      account_name: '',
      account_no: '',

      search: '',
      search_member: '',
      search_items: [],
      search2: null,
      search_member2: '',
      search_items2: [],
      search3: null,
      search_member3: '',
      search_items3: [],


      last_name: '',
      first_name: '',
      middle_name: '',
      permanent_address: '',
      date_of_birth: '',
      place_of_birth: '',
      gender: '',
      tin_no_personal: '',
      sss_no: '',
      religion: '',
      civil_status: '',
      no_of_dependents: 0,
      educational_attainment: '',
      present_address: '',
      residence_is: '',
      residence_specify: '',
      zip_code: '',
      citizenship: '',
      contact_no: '',
      email_address_personal: '',

      last_name2: '',
      first_name2: '',
      middle_name2: '',
      permanent_address2: '',
      date_of_birth2: '',
      place_of_birth2: '',
      gender2: '',
      tin_no_personal2: '',
      sss_no2: '',
      religion2: '',
      civil_status2: '',
      no_of_dependents2: 0,
      educational_attainment2: '',
      present_address2: '',
      residence_is2: '',
      residence_specify2: '',
      zip_code2: '',
      citizenship2: '',
      contact_no2: '',
      email_address_personal2: '',

      last_name3: '',
      first_name3: '',
      middle_name3: '',
      permanent_address3: '',
      date_of_birth3: '',
      place_of_birth3: '',
      gender3: '',
      tin_no_personal3: '',
      sss_no3: '',
      religion3: '',
      civil_status3: '',
      no_of_dependents3: 0,
      educational_attainment3: '',
      present_address3: '',
      residence_is3: '',
      residence_specify3: '',
      zip_code3: '',
      citizenship3: '',
      contact_no3: '',
      email_address_personal3: '',

      type_of_account_corporate: '',
      business_address: '',
      date_of_registration: '',
      place_of_registration: '',
      registration_no: '',
      tin_no: '',
      nature_of_business: '',
      gross_annual_income: 0,
      telephone_no: '',
      fax_no: '',
      email_address: '',
      authorized_representative_name: '',
      authorized_representative_contact_no: '',
      financial_details_gscc: '',
      financial_details_other_banks: '',
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
      }
    },
    created() {
      this.account_no_data()
    },
    data() {
      return initialState()
    },
    watch: {
      search_member: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member == '') {
            this.search_items = []
          } else {
            this.searching_search_items(this.search_member)
          }
        }, 500)
      },
      search_member2: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member2 == '') {
            this.search_items2 = []
          } else {
            this.searching_search_items2(this.search_member2)
          }
        }, 500)
      },
      search_member3: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member3 == '') {
            this.search_items3 = []
          } else {
            this.searching_search_items3(this.search_member3)
          }
        }, 500)
      },
      type_of_account: function () {
        this.account_name = ''
        if (this.type_of_account === 'Personal' || this.type_of_account === 'Junior') {
          this.search2 = null
          this.search3 = null
        } else if (this.type_of_account === 'Joint') {
          this.search3 = null
        }
      },
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['month_start', 'month_end', 'month_of', 'branch', 'branch_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('members_information', ['search_members', 'search_all_members','create_corporate']),
      reset() {
        Object.assign(this.$data, initialState())

        this.account_no_data()
      },
      croppie(e) {
        this.show = true;
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;

        var reader = new FileReader();
        reader.onload = e => {
          this.cropped = this.croppieImage_mem_1 = e.target.result
        };
        reader.readAsDataURL(files[0]);
      },
      croppie_bus(e) {
        this.show_bus = true;
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;

        var reader = new FileReader();
        reader.onload = e => {
          this.cropped_bus = this.croppieImage_mem_2 = e.target.result
        };

        reader.readAsDataURL(files[0]);
      },
      croppie_home(e) {
        this.show_home = true;
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;

        var reader = new FileReader();
        reader.onload = e => {
          this.cropped_home = this.croppieImage_mem_3 = e.target.result
        };

        reader.readAsDataURL(files[0]);
      },
      resett() {
        this.$refs.refInputEl.value = ''
        this.cropped = this.avatarImg

        this.$refs.refInputEl_bus.value = ''
        this.cropped_bus = this.avatarImg

        this.$refs.refInputEl_home.value = ''
        this.cropped_home = this.avatarImg
      },
      account_no_data() {
        this.account_no = 'GSCC-' + moment().year() + '-' + moment().seconds() + '' + moment().minutes() + '' + moment().hours() + '-' + moment().date() + '' + (moment().month() + 1)
      },
      searching(value) {
        this.search_member = value
      },
      searching2(value) {
        this.search_member2 = value
      },
      searching3(value) {
        this.search_member3 = value
      },
      searching_search_items(value) {
        this.search_all_members({
          branch_id: this.branch_id,
          search_word: value
        })
          .then(response => {
            this.search_items = response.data
          })
      },
      searching_search_items2(value) {
        this.search_all_members({
          branch_id: this.branch_id,
          search_word: value
        })
          .then(response => {
            this.search_items2 = response.data
          })
      },
      searching_search_items3(value) {
        this.search_all_members({
          branch_id: this.branch_id,
          search_word: value
        })
          .then(response => {
            this.search_items3 = response.data
          })
      },
      get_search_items_info() {
        if (this.search_items.length > 0) {
          var index = this.search_items.map(function (x) {
            return x.id;
          }).indexOf(this.search.id)
          if (index != -1) {
            this.last_name = this.search_items[index].member.last_name
            this.first_name = this.search_items[index].member.first_name
            this.middle_name = this.search_items[index].member.middle_name
            this.permanent_address = this.search_items[index].member.permanent_address
            this.gender = this.search_items[index].member.gender
            this.$refs.form.validate()
          } else {
            this.reset()
          }
        } else {
          this.reset()
        }
      },
      get_search_items_info2() {
        if (this.search_items2.length > 0) {
          var index = this.search_items2.map(function (x) {
            return x.id;
          }).indexOf(this.search2.id)
          if (index != -1) {
            this.last_name2 = this.search_items2[index].member.last_name
            this.first_name2 = this.search_items2[index].member.first_name
            this.middle_name2 = this.search_items2[index].member.middle_name
            this.permanent_address2 = this.search_items2[index].member.permanent_address
            this.gender2 = this.search_items2[index].member.gender
            this.$refs.form.validate()
          } else {
            this.reset()
          }
        } else {
          this.reset()
        }
      },
      get_search_items_info3() {
        if (this.search_items3.length > 0) {
          var index = this.search_items3.map(function (x) {
            return x.id;
          }).indexOf(this.search3.id)
          if (index != -1) {
            this.last_name3 = this.search_items3[index].member.last_name
            this.first_name3 = this.search_items3[index].member.first_name
            this.middle_name3 = this.search_items3[index].member.middle_name
            this.permanent_address3 = this.search_items3[index].member.permanent_address
            this.gender3 = this.search_items3[index].member.gender
            this.$refs.form.validate()
          } else {
            this.reset()
          }
        } else {
          this.reset()
        }
      },

      save_loan_application() {
        this.saving = true
        this.alert = false
        var flag = false
        if (moment(this.date_of_registration, 'YYYY-MM-DD').format('MMMM D, YYYY') === moment(this.month_start, 'MMMM D, YYYY').format('MMMM D, YYYY')
          || moment(this.date_of_registration, 'YYYY-MM-DD').format('MMMM D, YYYY') === moment(this.month_end, 'MMMM D, YYYY').format('MMMM D, YYYY')) {
          flag = true
        } else {
          var compareDate = moment(this.date_of_registration, 'YYYY-MM-DD')
          var startDate = moment(this.month_start, 'MMMM D, YYYY')
          var endDate = moment(this.month_end, 'MMMM D, YYYY')

          flag = compareDate.isBetween(startDate, endDate) //false in this case
        }
        if (flag === false) {
          this.date_of_registration = ''
          this.alert = true
          this.alert_message = 'Date of Opening is Not Belong in Transaction Month!'
          this.saving = false
        } else {
          var proceed = true;
          if (proceed) {
            if (this.$refs.form.validate()) {
              const data = new FormData()
              data.append('branch_id', this.branch_id);
              data.append('account_name', this.account_name.toUpperCase());

              data.append('type_of_account_corporate', this.type_of_account_corporate);
              data.append('business_address', this.business_address);
              data.append('date_of_registration', this.date_of_registration);
              data.append('place_of_registration', this.place_of_registration);
              data.append('registration_no', this.registration_no);
              data.append('tin_no', this.tin_no);
              data.append('nature_of_business', this.nature_of_business);
              data.append('gross_annual_income', this.gross_annual_income);
              data.append('telephone_no', this.telephone_no);
              data.append('fax_no', this.fax_no);
              data.append('email_address', this.email_address);
              data.append('financial_details_gscc', this.financial_details_gscc);
              data.append('financial_details_other_banks', this.financial_details_other_banks);

              this.create_corporate(data)
                .then(response => {
                  this.change_snackbar({
                    show: true,
                    color: 'success',
                    text: response.data,
                  })
                  this.reset()
                })
                .catch(error => {
                  this.alert = true
                  this.alert_message = error
                  console.log(error)
                  this.saving = false
                })
            } else {
              this.alert = true
              this.alert_message = 'Please Fill up the field/s'
              this.saving = false
            }
          }
        }
      }
    }
  }
</script>
